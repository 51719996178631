import React from "react";
import { ThemeProvider } from 'styled-components';
import theme from "./src/components/styled/themeVariables";

export const wrapPageElement = ({ element }) => {
  return (
    <ThemeProvider theme={theme}>
      {element}
    </ThemeProvider>
  );
};
